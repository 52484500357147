<template>
<div class="page-content">
    <img src="@/assets/img/writer-benefits/bg-left-1.png" width="342" height="750" class="bg-left-1" />
    <img src="@/assets/img/writer-benefits/bg-left-2.png" width="366" height="814" class="bg-left-2" />
    <img src="@/assets/img/writer-benefits/bg-right.png" width="278" height="753" class="bg-right" />
    <h1 class="page-title">Writer Benefits</h1>
    <!-- To Author -->
    <section class="flex page-section to-author">
<!--        <div class="flex-item-1 section-left">-->
<!--            <div class="section-title-1">To Our Authors:</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">-->
<!--                    With the aspiration of building a world-leading reading platform, we have been trying our best to create a  better environment for authors to write and readers to read. Now, many famous best-selling authors have joined us and many new writers have started their literature journey on FRIVER.<br/>To promote the development of global literature and, here, we gently treat all the talented writers. Each of you who has a dream counts. Let the dream of creation set sail here. You write down your shining stars, and we will jointly light up a starry night.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <img src="@/assets/img/writer-benefits/author-benefits.png" width="100%" height="100%" />
    </section>
    <!-- Author Support -->
<!--    <section class="page-section author-support">-->
<!--        <div class="section-title-1">The following supports will be offered to our authors:</div>-->
<!--        <div class="support-item">-->
<!--            <div class="section-title-2 bg-num-1">• A Generous Share of Bonuses and Profit:</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">-->
<!--                    We have a Signing Bonus of $50, an Updating Bonus of $180, and a Completion and Renewal Bonus of up to $300. In addition, we also offer advanced payment or buyout payment for exclusive novels and a share of the distributive income after publishing on our platform for all the exclusive novels.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="support-item">-->
<!--            <div class="section-title-2 bg-num-2">• Your Novel(s) Will Be Promoted to Millions of Readers:</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">-->
<!--                    After signing your novels, we will recommend them to millions of our users. We will help you gain more followers, and hold various activities to get readers tuned in. FRIVER enjoys a good reputation among readers. Therefore, you can build your fan base here to make your novels popular all over the world.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="support-item">-->
<!--            <div class="section-title-2 bg-num-3">• One-on-One Guidance from an Editor:</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">A professional and experienced editor will be assigned to assist you.</div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="support-item">-->
<!--            <div class="section-title-2 bg-num-4">• Copyright-to-IP Development:</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">-->
<!--                    We will recommend excellent novels to film and animation companies around the world. Your stories may be possible to be adapted into animations, TV dramas and films to gain worldwide attention. Let the world hear your voice!-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
<!--    &lt;!&ndash; Bonus &ndash;&gt;-->
<!--    <section class="page-section bonus-container">-->
<!--        <div class="section-title-1">Bonuses for Exclusive FRIVER Stories with Share Only</div>-->
<!--        <div class="bonus-item">-->
<!--            <div class="text-center title-container">-->
<!--                <div class="bonus-item-title">-->
<!--                    <span class="text-first-line">Signing Bonus</span><br/>-->
<!--                    <span class="text-second-line">Come and create your own story.</span>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="text-center flex section-table">-->
<!--                <div class="flex-item-1 table-left">-->
<!--                    <div class="text-title text-lg">Number of Followers</div>-->
<!--                    <div class="table-num">200</div>-->
<!--                </div>-->
<!--                <div class="flex-item-1 table-right">-->
<!--                    <div class="text-title">Bonus</div>-->
<!--                    <div class="table-num">50USD</div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="section-content bonus-item-desc">-->
<!--                <div class="p-lg">-->
<!--                    You can claim this bonus when your story meets all of the following conditions:<br/>1.Your story gets signed;<br/>2.Your story gets 200 followers;<br/>3.Your story exceeds 20,000 words.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="bonus-item">-->
<!--            <div class="text-center title-container">-->
<!--                <div class="bonus-item-title">-->
<!--                    <span class="text-first-line">Updating Bonus</span><br/>-->
<!--                    <span class="text-second-line">Longer Story, More Reward!</span>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="text-center flex section-table">-->
<!--                <div class="flex-item-1 table-left">-->
<!--                    <div class="text-title text-lg">Longer Story, More Reward!</div>-->
<!--                    <div class="table-num">≥ 50,000 words</div>-->
<!--                </div>-->
<!--                <div class="flex-item-1 table-right">-->
<!--                    <div class="text-title">Bonus</div>-->
<!--                    <div class="table-num">180USD</div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="section-content mt30">-->
<!--                <div class="p-lg">-->
<!--                    You can claim this bonus when your story meets all of the following conditions:<br/>-->
<!--                    1.Your story gets signed and contains VIP chapters;-->
<!--                </div>-->
<!--                <div class="mt20">-->
<!--                    <div class="p-lg">2.You update 50,000 words and above in a calendar month;</div>-->
<!--                </div>-->
<!--                <div class="mt20">-->
<!--                    <div class="p-lg">-->
<!--                        3.You publish at least a new chapter to such a story daily with a maximum of 3 days absent allowed in a calendar month (after you publish the first chapter and before you publish the last one).-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="mt24">-->
<!--                    <div class="p-lg desc-tip">-->
<!--                        P.S.:<br/>To encourage authors to create longer stories, FRIVER will hold more exciting events in the future. Stay tuned and earn more!-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="bonus-item">-->
<!--            <div class="text-center title-container">-->
<!--                <div class="bonus-item-title">-->
<!--                    <span class="text-first-line">Completion and Renewal Bonus</span><br/>-->
<!--                    <span class="text-second-line">The longer the story, the more you earn!</span>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="text-center flex section-table">-->
<!--                <div class="flex-item-1 table-left">-->
<!--                    <div class="text-title text-lg">Number of Words</div>-->
<!--                    <div class="table-num">60,000-99,999</div>-->
<!--                    <div class="table-num">100,000-149,999</div>-->
<!--                    <div class="table-num">150,000-199,999</div>-->
<!--                    <div class="table-num">≥ 200,000</div>-->
<!--                </div>-->
<!--                <div class="flex-item-1 table-right">-->
<!--                    <div class="text-title">Bonus</div>-->
<!--                    <div class="table-num">60USD</div>-->
<!--                    <div class="table-num">180USD</div>-->
<!--                    <div class="table-num">250USD</div>-->
<!--                    <div class="table-num">300USD</div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="mt80">-->
<!--                <div class="section-title-2">You can claim this bonus when your story meets all of the following conditions:</div>-->
<!--                <div class="section-content">-->
<!--                    <div>• Your story gets signed and contains VIP chapters;</div>-->
<!--                    <div class="mt30">-->
<!--                        <div class="p-lg">-->
<!--                            • Your story is completed and meets any of the above ranges, after which you publish a new story over 20,000 words on FRIVER within two months and release VIP chapters;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mt30">-->
<!--                        <div class="p-lg">-->
<!--                            • You publish at least a new chapter to such a story daily with a maximum of 3 days absent allowed every calendar month (after you publish the first chapter and before you publish the last one);-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mt24 desc-tip">-->
<!--                        <div>Note:</div>-->
<!--                        <div class="mt30">-->
<!--                            * Stories which have been published on other platforms as complete ones shall not be involved.-->
<!--                        </div>-->
<!--                        <div class="mt30">-->
<!--                            * If there's any quality issue found in such a story, the bonuses mentioned above will be cancelled accordingly.-->
<!--                        </div>-->
<!--                        <div class="mt30">-->
<!--                            <div class="p-lg">-->
<!--                                * All published chapters of such a story shall be reviewed and approved by your editor. The numbers of updating days and words are calculated after review and based on UTC+8.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
<!--    &lt;!&ndash; Payment Plan &ndash;&gt;-->
<!--    <section class="page-section payment-plan">-->
<!--        <div class="section-title-1">Advance Payment and Buyout Plan for Other Exclusive FRIVER Stories</div>-->
<!--        <div class="section-content">-->
<!--            <div class="p-lg">-->
<!--                • This plan is for authors who have story(s) with excellent marketing performance in the past and are willing to create new and original stories on FRIVER exclusively.-->
<!--            </div>-->
<!--            <div class="mt30">-->
<!--                <div class="p-lg">-->
<!--                    • When you apply for such a contract for your new story, your editor will make an offer for you considering the quality of the story and the performance of your previous stories.<br/>This plan excludes the bonuses mentioned above.-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="mt30">• To apply for such a contract, you need to meet the requirements as below:</div>-->
<!--            <div class="mt30">1. Submit an outline of your story with 5,000 words and above.</div>-->
<!--            <div class="mt30">2. Attach the link(s) to your previous story(s) or any other marketing performance proof(s).</div>-->
<!--        </div>-->
<!--        <div class="mt24 text-lg">-->
<!--            <div>Note:</div>-->
<!--            <div class="mt30">* Only new and original stories that are exclusively published on FRIVER will be included in this plan;</div>-->
<!--            <div class="mt30">* Authors can apply for such a contract only once at a time when the story is ongoing;</div>-->
<!--            <div class="mt30">* Specify if you prefer an advance payment contract or a buyout contract when applying;</div>-->
<!--        </div>-->
<!--        <div class="mt80 section-content">-->
<!--            <div class="section-title-2">Rules of Settlement:</div>-->
<!--            <div class="">1.Publish at least a new chapter to such a story daily with a maximum of 3 days absent allowed every calendar month.</div>-->
<!--            <div class="mt30">2.Publish at least 30,000 words of the story in a calendar month, except for the starting month and the ending month.</div>-->
<!--            <div class="mt30">3.Ensure that the story is not less than 60,000 words when completed.</div>-->
<!--            <div class="mt30">-->
<!--                <div class="p-lg">-->
<!--                    4.Only when the story reaches 30,000 words and contains VIP chapters on FRIVER, can the author claim the advance payment or the buyout payment.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
<!--    &lt;!&ndash; Reward &ndash;&gt;-->
<!--    &lt;!&ndash; Income &ndash;&gt;-->
<!--    <section class="page-section">-->
<!--        <div class="section-title-1">Share Income</div>-->
<!--        <div class="section-content p-lg">-->
<!--            Under a contract with share, the more readers follow your story(s) and purchase VIP chapters, the higher your share income will be. Your friends may be among your story followers!-->
<!--        </div>-->
<!--        <table class="mt36 table">-->
<!--            <colgroup>-->
<!--                <col width="50%">-->
<!--                <col>-->
<!--            </colgroup>-->
<!--            <thead>-->
<!--                <tr>-->
<!--                    <th>Income Types</th>-->
<!--                    <th>Share</th>-->
<!--                </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--                <tr>-->
<!--                    <td>Distribution or sale of the story in digital format</td>-->
<!--                    <td>50%</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>Distribution or sale of physical books (paperback, hardcover, etc.)</td>-->
<!--                    <td>50%</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>Distribution or sale of the story in digital format on authorized third-party platforms</td>-->
<!--                    <td>50%</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>Adaption of the story into film and TV, games, visual literature, graphic novels, and audio forms, etc.</td>-->
<!--                    <td>50%</td>-->
<!--                </tr>-->
<!--            </tbody>-->
<!--        </table>-->
<!--        <div class="mt30 section-content">-->
<!--            <div class="p-lg">-->
<!--                An author will be disqualified to receive any bonus when:<br/>1. the story involves plagiarism or infringement to others' copyright;-->
<!--            </div>-->
<!--            <div class="mt30">2. the author has any fraudulent behaviours, for instance, rigging the votes, rankings, subscriptions, etc.;</div>-->
<!--            <div class="mt30">-->
<!--                <div class="p-lg">-->
<!--                    3. the author has another contracted but unfinished or abnormally completed story(s), in which case, if the author fails to update a story over 7 days, all bonuses will be delayed upon the completion of the story; if the author fails to update a story over 15 days, or accumulatively over 30 days;-->
<!--                    4. the author applies any means of deception (including writing meaningless plots, repeating what has been written before, copying online information, etc.), or any illegal means (including writing illegal pornography, politically forbidden content, etc.), special characters, or deliberately pads out the story (including insipid plots, poor pace, meaningless content at great length, etc.) to achieve the word count of published content required by this incentive plan.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
<!--    &lt;!&ndash; Submit &ndash;&gt;-->
<!--    <section class="page-section">-->
<!--        <div class="section-title-1">How To Submit An Article?</div>-->
<!--        <div>-->
<!--            <div class="section-title-2">• What genres do we want?</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">-->
<!--                    1. Paranormal genre: Imaginative depictions of vampires, werewolves, witches, and other exciting stories in the real world, such as Twilight, The Vampire Diaries, Witches of East End, Southern Vampire.-->
<!--                </div>-->
<!--                <div class="mt30">-->
<!--                    <div class="p-lg">-->
<!--                        2. Modern romance genre: A touching, ups and downs love story set in the real world. Your work can be a modern Cinderella story, or a story about the entanglements of married life, for examples, Fifty Shades of Grey, Gossip Girl, My Husband, Warm The Bed!-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="mt30">-->
<!--                    <div class="p-lg">-->
<!--                        3. Fantasy genre: Fantastical and interesting stories set in fantasy worlds, e.g., Harry Potter, The Lord of the Rings.-->
<!--                        4. Other genres: Other creative and interesting novels, including science fictions, adventure stories, etc. .-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="mt80">-->
<!--            <div class="section-title-2">• How to submit a novel?</div>-->
<!--            <div class="section-content">-->
<!--                <div class="p-lg">-->
<!--                    Authors are required to send an outline and the first three chapters of the novel by email to us and we will reply within 5 working days after receipt.-->
<!--                </div>-->
<!--                <div class="mt30">-->
<!--                    <div class="p-lg">-->
<!--                        * The outline should include a detailed description of the main characters, story background, plot development, highlights and ending. A story of 1000 words per chapter is recommended.-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--    </section>-->
</div>
</template>

<script>

export default {
  name: 'DesktopWriterBenefits',
  created() {}
}
</script>

<style lang="less" scoped>
@import (reference) "~base.less";

.page-content {
    padding: 88px 0 170px;
    position: relative;
    .body-bg(@color-white);

    .bg-left-1 {
        position: absolute;
        left: 0;
        top: 880px;
        z-index: -1;
    }
    .bg-left-2 {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 4620px;
    }
    .bg-right {
        position: absolute;
        z-index: -1;
        right: 0;
        top: 2500px;
    }
}
.page-title {
    padding-bottom: 4px;
    font-size: 36px;
    font-weight: bolder;
    color: @color-text-title;
    text-align: center;
}
.page-section {
    padding: 0 196px;
    width: @page-width;
    margin: 80px auto 0;
    box-sizing: border-box;

    .section-title-1 {
        margin-bottom: 30px;
        font-size: @fontsize-xl;
        font-weight: bold;
        color: @color-text-title;
    }
    .section-title-2 {
        margin-bottom: 16px;
        font-size: @fontsize-lger;
        font-weight: bold;
        color: @color-primary;
    }
    .section-content {
        color: @color-text-title;
        text-align: justify;
    }
    .section-table {
        border: @border-base;
        .table-left, .table-right {
            padding: 32px 0 24px;
        }
        .table-left {
            border-right: @border-base;
        }
        .table-num {
            margin-top: 24px;
            color: @color-text-title;
            font-size: @fontsize-lg;
        }
    }
}
// To Author
.to-author {
    .section-left {
        margin-right: 32px;
    }
}
// Author Support
.author-support {
    .section-title-1 {
        margin-bottom: -16px;
    }
    .section-title-2 {
        margin-bottom: 0;
        padding: 16px 0 16px;
    }
    .support-item {
        margin-top: 30px;
    }
    .bg-num-1 {
        background: url(~@/assets/img/writer-benefits/num-1.png) no-repeat left center / 27px 51px;
    }
    .bg-num-2 {
        background: url(~@/assets/img/writer-benefits/num-2.png) no-repeat left center / 27px 51px;
    }
    .bg-num-3 {
        background: url(~@/assets/img/writer-benefits/num-3.png) no-repeat left center / 27px 51px;
    }
    .bg-num-4 {
        background: url(~@/assets/img/writer-benefits/num-4.png) no-repeat left center / 27px 51px;
    }
}
// Bonus
.bonus-container {
    .bonus-item {
        margin-bottom: 80px;

        .title-container {
            margin-bottom: -26px;
            position: relative;
            z-index: 1;
        }
        &-title {
            display: inline-block;
            box-sizing: border-box;
            border-radius: 36px;
            min-width: 342px;
            text-align: center;
            padding: 14px 20px;
            color: @color-white;
            font-size: 0;
            background: linear-gradient(270deg, #0068e6 0%, #007de6 100%);
            .text-first-line {
                display: inline-block;
                font-size: @fontsize-xl;
                font-weight: bold;
            }
            .text-second-line {
                display: inline-block;
                font-size: @fontsize-lg;
                margin-top: 6px;
                color: #9dbfff;
            }
        }

        .desc-tip {
            color: @color-text;
        }
    }
}
// Reward
.section-reward {
    .section-title-1 {
        margin-bottom: 16px;
    }
}
</style>
